


























































































import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { FlowsService, SuccessGetUser } from '@/includes/services/FlowService'
import { warningNotification } from '@/includes/services/NotificationService'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'

@Component
export default class UsersInfoTable extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @Prop() values!:Array<number>

  @Emit('input')
  input(): Array<number> {
    return this.knownUsers.map(u => u.info.id)
  }

  knownUsers: Array<Omit<SuccessGetUser, 'status'>> = []

  userData = ''

  checkUsers(): void {
    if (this.userData.trim().length) {
      this.doUsersCheck([ this.userData.trim() ])
    } else {
      warningNotification(this.$t('user_info_table_input_empty').toString())
    }
  }

  removeUser(id: number): void {
    const index = this.knownUsers.findIndex((user) => user.info.id === id)

    if (index !== -1) {
      this.knownUsers.splice(index, 1)
      this.input()
    }
  }

  doUsersCheck(values: Array<string> | Array<number>):void {
    FlowsService.getUsersInfo('tg', {
      board_key: this.$store.state.boardsState.activeBoard!.board,
      values
    }).then(({ data }) => {
      if (!data.length) {
        warningNotification(this.$t('user_info_get_not_found').toString())
      } else {
        if (data.length && !this.knownUsers.find((user) => user.info.id === data[0].info.id)) {
          this.knownUsers.push(...data)
          this.input()

          this.userData = ''
        }
      }
    })
  }

  created(): void {
    if (this.values && this.values.length) {
      this.doUsersCheck(this.values)
    }
  }
}
