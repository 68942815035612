var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"users-info-table"},[_c('div',{staticClass:"flex items-end"},[_c('text-input',{staticClass:"w-full",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'userData',
          'prefix': 'distribution_'
        },
      }},on:{"pressEnter":_vm.checkUsers}}),_c('a-button',{staticClass:"mb-5",attrs:{"icon":"search","type":"primary"},on:{"click":_vm.checkUsers}},[_vm._v(" "+_vm._s(_vm.$t('distribution_pre_check_add_user'))+" ")])],1),_vm._t("default"),_c('a-table',{attrs:{"data-source":_vm.knownUsers,"row-key":function (record) { return ("user-" + (record.info.id)); },"locale":{
      emptyText: _vm.$t('list_empty')
    },"pagination":{
      defaultPageSize: 5
    }}},[_c('a-table-column',{key:"name",attrs:{"title":_vm.$t('distribution_users_condition_table_name_title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var info = ref.info;
return _c('span',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(info.name)+" ")])}}])}),_c('a-table-column',{key:"login",attrs:{"title":_vm.$t('distribution_users_condition_table_login_title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var info = ref.info;
return _c('span',{staticClass:"font-medium"},[_vm._v(" "+_vm._s(info.login ? ("@" + (info.login)) : '-')+" ")])}}])}),_c('a-table-column',{key:"id",attrs:{"title":"ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var info = ref.info;
return _c('span',{},[_vm._v(" "+_vm._s(info.id)+" ")])}}])}),_c('a-table-column',{key:"actions",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var info = ref.info;
return _c('div',{},[_c('a-button',{attrs:{"type":"danger","icon":"delete"},on:{"click":function($event){return _vm.removeUser(info.id)}}})],1)}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }