

















































import {
  MessageVariants,
  PostCommentsStateTypes,
  Style,
  WatermarkPosition,
  WatermarkType
} from '@/includes/types/Post.types'
import PostStylesSetup from '@/components/Post/PostStylesSetup.vue'
import PageTitle from '@/components/PageTitle.vue'
import { BoardService } from '@/includes/services/BoardService'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import Component from 'vue-class-component'
import { cloneDeep, isEmpty } from 'lodash'
import { Mixins } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import validateActions from '@/components/Post/logic/ValidateActions'

@Component({
  'components': {
    PageTitle,
    PostStylesSetup,
  },
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class StylePage extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  getBoardById!:GetBoardByIdType

  isObjectSet = false

  styleName = ''

  styleObject = {
    watermark_type: WatermarkType.None,
    watermark_position: WatermarkPosition.Center,
    watermark_data: null,
    prefix_message: this.messageObject,
    postfix_message: this.messageObject,
    protect_content: false,
    disable_notify: false,
    disable_link_preview: false,
    compress_level: null,
    pin: false,
    actions: [],
    data: {
      color: null,
      post_comments_state: PostCommentsStateTypes.None,
      post_price: 0
    }
  } as Style

  styles: Record<any, Style> | null = {}

  isLoading = false

  get currentBoard() {
    return this.getBoardById(this.$route.params.id)
  }

  get disabled(): boolean {
    return this.styleObject.watermark_type !== 'None' && isEmpty(this.styleObject.watermark_data) || !this.styleName
  }

  get styleKey(): string {
    return this.$route.query.styleId.toString()
  }

  get actionType(): BaseItemViewAction {
    return this.$route.params.actionType.toString() as BaseItemViewAction
  }

  get messageObject(): Array<MessageVariants> {
    return [ {
      buttons: [],
      text: '',
      remove_previous: false,
      pin: false,
      disable_link_preview: false,
      disable_notify: false,
      attachments: []
    } ]
  }

  saveStyle(): void {
    const styleObjectCopy = cloneDeep(this.styleObject)

    if (this.actionType === BaseItemViewAction.Edit || this.actionType === BaseItemViewAction.New || this.actionType === BaseItemViewAction.Copy) {

      const validationOk = validateActions(styleObjectCopy.actions)

      if (validationOk) {
        this.isLoading = true

        if (this.styles) {
          if (this.actionType === BaseItemViewAction.Edit && this.styleKey !== this.styleName) {
            this.$delete(this.styles, this.styleKey)
          }

          this.$set(this.styles, this.styleName, styleObjectCopy)
        }

        this.savePostBoardConfig()
      } else {
        errorNotification(this.$t('actions_are_not_valid_error'))
      }
    }

  }

  savePostBoardConfig():void {
    BoardService.setPostBoardConfig('tg', {
      board_key: this.currentBoard!.board,
      timezone: this.currentBoard!.timezone,
      lang: this.currentBoard!.lang,
      config: { ...this.$store.state.boardsState.activeBoard?.config, styles: this.styles }
    })
      .then((data) => {
        this.$store.commit('set_active_board_config', data)
        successNotification()
      })
      .catch(errorNotification)
      .finally(() => {
        this.$router.push({ name: 'Styles' })
      })

  }

  mounted(): void {
    this.isLoading = true

    const boardConfig = this.$store.state.boardsState.activeBoard

    if (boardConfig) {
      if (isEmpty(boardConfig.config)) {
        this.styles = {}
      } else {
        this.styles = boardConfig.config.styles
      }

      if (this.styles === null || this.actionType === BaseItemViewAction.New) {
        this.isObjectSet = true
        this.isLoading = false
        this.styles = boardConfig.config.styles === null ? {} : boardConfig.config.styles
      } else {
        this.styles = cloneDeep(boardConfig.config.styles)

        if (this.styles !== null) {

          const style = this.styles[this.styleKey]

          this.styleObject = style

          if (this.actionType === BaseItemViewAction.Copy) {
            delete this.styleObject.id
          }

          this.styleName = this.styleKey
          this.isObjectSet = true
          this.isLoading = false
        }
      }
    }
  }

}
