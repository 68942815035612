











































































































import { InputSetups } from '@/mixins/input-setups'
import { ForwarMessageActionMode, PostAction, PostActionType } from '@/includes/types/Post.types'
import { actionLabel } from '@/mixins/ActionLabels'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import UsersInfoTable from '@/components/UsersInfoTable.vue'
import ChannelsInfoTable from '@/components/ChannelsInfoTable.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { MessageEditorWithMediaTab } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { SelectOption } from 'piramis-base-components/src/logic/types'

import Component from 'vue-class-component'
import { Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  components: { ChannelsInfoTable, UsersInfoTable },
  data() {
    return {
      actionLabel,
      MessageEditorWithMediaTab,
      EditorButtonsHelpView
    }
  }
})
export default class ActionItem extends Mixins(UseFields, InputSetups) {

  @VModel() action!: PostAction & { label: string }

  @Prop({ required: true, type: String }) board !: string

  @Prop({ type: Number }) itemIndex !: number

  @Prop({ type: Number }) lastIndex !: number

  @Prop({ type: Boolean, default: false }) disabled!:boolean

  PostActionType: any = PostActionType

  get isMoveDownButtonDisabled(): boolean {
    return this.itemIndex === this.lastIndex
  }

  get isMoveUpButtonDisabled(): boolean {
    return this.itemIndex === 0
  }

  get messageEditorArgs(): any {
    const args = {
      'key':'variants',
      'prefix': 'post_',
      'validation': 'required',
      'availableButtonsTypes': this.defaultMessageEditorButtons,

      'targetToUpload': () => {
        return {
          'target': 'target',
          'target_id': this.$store.getters.getBoardById(this.$route.params.id).board
        }
      },
      'has-media': true,
      'buttonsHelpView': EditorButtonsHelpView,
      'base-api-url': 'api.presscode.app/cabinet',
      'availableTabs': [ MessageEditorWithMediaTab.EditorTab, MessageEditorWithMediaTab.TextareaTab ],
      'disabled': this.disabled,
      'settingsStructure': {
        'disable_link_preview': false,
      }
    }

    if (this.action.action.type === PostActionType.SendCommentAction) {
      args.availableTabs.push(MessageEditorWithMediaTab.SettingsTab)
      Object.assign(args.settingsStructure, { remove_after: 0 })
    }

    if (this.action.action.type === PostActionType.NotifyUsersAction) {
      args.key = 'message'
      args['has-media'] = true
      args.availableButtonsTypes = this.pmButtons
    }

    return args
  }
}
