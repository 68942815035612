var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-styles-setup"},[(_vm.styleCopy)?_c('div',[_c('div',{staticClass:"flex flex-col md:flex-row md:justify-between md:items-start"},[_c('number-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.styleCopy.data,
            'key': 'post_price',
            'min': 0,
            'disabled': _vm.disabled
          }
        }}}),_c('post-color-select',{staticClass:"ml-2 md:ml-0",attrs:{"disabled":_vm.disabled},model:{value:(_vm.styleCopy.data.color),callback:function ($$v) {_vm.$set(_vm.styleCopy.data, "color", $$v)},expression:"styleCopy.data.color"}})],1),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy,
          'key': 'protect_content',
          'disabled': _vm.disabled
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy,
          'key': 'disable_notify',
          'disabled': _vm.disabled
        }
      }}}),(_vm.postType === null || _vm.postType === 'Post')?_c('div',[_c('switch-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.styleCopy,
            'key': 'disable_link_preview',
            'disabled': _vm.disabled
          }
        }}})],1):_vm._e(),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy,
          'key': 'pin',
          'disabled': _vm.disabled
        }
      }}})],1):_vm._e(),_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.styleCopy.data,
        'key': 'post_comments_state',
        'options': _vm.postCommentsStateOptions,
        'multiple': false,
        'disabled': _vm.disabled
      },
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.styleCopy,
        'key': 'compress_level',
        'options': _vm.compressLevelOptions,
        'multiple': false,
        'disabled': _vm.disabled
      },
    }}}),(_vm.postType === null || _vm.postType === 'Post')?_c('div',[_c('div',{staticClass:"mr-5"},[_c('multi-message-editor-with-media-input',{staticClass:"w-full",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'hasMedia': true,
            'model': _vm.styleCopy,
            'key': 'prefix_message',
            'availableButtonsTypes': _vm.defaultMessageEditorButtons,
            'base-api-url': 'api.presscode.app/cabinet',
            'targetToUpload': function () {
              return {
                'target': 'target',
                'target_id': _vm.$store.getters.getBoardById(_vm.$route.params.id).board
              }
            },
            'buttonsHelpView': _vm.EditorButtonsHelpView,
            'availableTabs': [_vm.MessageEditorWithMediaTab.EditorTab, _vm.MessageEditorWithMediaTab.TextareaTab],
            'disabled': _vm.disabled
          }
        }}}),_c('multi-message-editor-with-media-input',{staticClass:"w-full",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.styleCopy,
            'key': 'postfix_message',
            'hasMedia': true,
            'availableButtonsTypes': _vm.defaultMessageEditorButtons,
            'base-api-url': 'api.presscode.app/cabinet',
            'targetToUpload': function () {
              return {
                'target': 'target',
                'target_id': _vm.$store.getters.getBoardById(_vm.$route.params.id).board
              }
            },
            'buttonsHelpView': _vm.EditorButtonsHelpView,
            'availableTabs': [_vm.MessageEditorWithMediaTab.EditorTab, _vm.MessageEditorWithMediaTab.TextareaTab],
            'disabled': _vm.disabled
          }
        }}})],1),_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy,
          'key': 'watermark_type',
          'options': _vm.watermarkTypes,
          'multiple': false,
          'clearable': false,
          'disabled': _vm.disabled
        },
      }},on:{"input":_vm.onSelectChange}}),(_vm.styleCopy.watermark_type !== 'None' && _vm.styleCopy.watermark_type !== null )?_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy,
          'key': 'watermark_position',
          'options': _vm.watermarkPosition,
          'multiple': false,
          'clearable': false,
          'disabled': _vm.disabled
        },
      }}}):_vm._e(),(_vm.styleCopy.watermark_type === 'Text')?_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy,
          'key': 'watermark_data',
          'validation': 'required',
          'disabled': _vm.disabled
        },
      }}}):_vm._e(),(_vm.styleCopy.watermark_type === 'File')?_c('file-uploader-targets-input',{staticClass:"watermark_uploader",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy,
          'key': 'watermark_data',
          'multiple': false,
          'base-api-url': 'api.presscode.app/cabinet',
          'available-mime-types': ['image/png', 'image/jpeg' ],
          'uploadType': _vm.FileType.Document,
          'targetToUpload': function () {
            return {
              'target': 'target',
              'target_id': _vm.$store.getters.getBoardById(_vm.$route.params.id).board
            }
          },
          'disabled': _vm.disabled

        },
      }}}):_vm._e(),_c('div',{staticClass:"actions mx-2"},[_c('accordion',{attrs:{"close-title":_vm.$t('accordion_post_actions_title'),"open-title":_vm.$t('accordion_post_actions_title')},model:{value:(_vm.actionsAccordionOpen),callback:function ($$v) {_vm.actionsAccordionOpen=$$v},expression:"actionsAccordionOpen"}},[_c('action-list',{attrs:{"board":_vm.$store.getters.getBoardById(_vm.$route.params.id).board,"action-list":_vm.filteredActions,"actions-disabled":_vm.postActionType === 'new',"disabled":_vm.disabled},model:{value:(_vm.styleCopy.actions),callback:function ($$v) {_vm.$set(_vm.styleCopy, "actions", $$v)},expression:"styleCopy.actions"}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }