

















































import PostActionTypePopup from '@/components/Post/PostActionTypePopup.vue'
import ActionItem from '@/components/Post/Actions/ActionItem.vue'
import { IPostActionMessage, PostAction } from '@/includes/types/Post.types'

import Component from 'vue-class-component'
import { Prop, VModel, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import Vue from 'vue'

@Component({
  components: {
    PostActionTypePopup,
    ActionItem,
  }
})
export default class ActionList extends Vue {

  @VModel() actions !: Array<PostAction>

  @Prop({ required: true, type: String }) board !: string

  @Prop() actionList!: Array<IPostActionMessage & { label: string, icon: string }>

  @Prop({ type: Boolean, default: false }) disabled!:boolean

  @Prop({ type:Boolean, default: false }) validateActions!:boolean

  @Prop({ type: Boolean, default: null }) actionsDisabled !: boolean

  actionsCopy: Array<PostAction> = []

  rerenderKey = 0

  areActionTypesOpen = false

  @Watch('actionsCopy', { deep: true })
  onActionsCopyChange(value: Array<PostAction> | undefined) {
    if (value) {
      this.actions = cloneDeep(value).map((a: any) => {
        delete a.key
        delete a.label
        return a
      })
    }

    this.areActionTypesOpen = false
  }

  get isActionsDisabled():boolean {
    return this.disabled || this.actionsDisabled
  }

  removeAction(index: number): void {
    this.actionsCopy.splice(index, 1)
    this.rerenderKey += 1
  }

  addAction(action: IPostActionMessage): void {
    const actionCopy = cloneDeep(action)
    this.actionsCopy.push({ ...actionCopy, delay: 1 } as PostAction)
  }

  moveActionItemUp(index: number): void {
    const action = this.actionsCopy[index]
    this.actionsCopy.splice(index, 1)
    this.actionsCopy.splice(index - 1, 0, action)
    this.rerenderKey += 1
  }

  moveActionItemDown(index: number): void {
    const action = this.actionsCopy[index]
    this.actionsCopy.splice(index, 1)
    this.actionsCopy.splice(index + 1, 0, action)
    this.rerenderKey += 1
  }

  mounted(): void {
    this.actionsCopy = this.actions ?? []
  }

}
