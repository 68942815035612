import { PostActionType } from '@/includes/types/Post.types'
import i18n from '@/i18n/i18n'

export function actionLabel(actionType:PostActionType): string {
    switch (actionType) {
        case PostActionType.PinMessageAction:
            return i18n.t('post_action_pin_message').toString()
        case PostActionType.PinChatMessageAction:
            return i18n.t('post_action_pin_chat_message').toString()
        case PostActionType.DeletePostAction:
            return i18n.t('post_action_delete_post').toString()
        case PostActionType.EditPostAction:
            return i18n.t('post_action_edit_post').toString()
        case PostActionType.NotifyUserAction:
            return i18n.t('post_action_notify_user').toString()
        case PostActionType.NotifyUsersAction:
            return i18n.t('post_action_notify_users').toString()
        case PostActionType.PinChatSilentMessageAction:
            return i18n.t('post_action_pin_chat_silent_message').toString()
        case PostActionType.PinSilentMessageAction:
            return i18n.t('post_action_pin_silent_message').toString()
        case PostActionType.SendCommentAction:
            return i18n.t('post_action_send_comment').toString()
        case PostActionType.SendReplyCommentAction:
            return i18n.t('post_action_send_reply_comment').toString()
        case PostActionType.SendReplyMessageAction:
            return i18n.t('post_action_send_reply_message').toString()
        case PostActionType.UnpinChatMessageAction:
            return i18n.t('post_action_unpin_chat_message').toString()
        case PostActionType.UnpinMessageAction:
            return i18n.t('post_action_unpin_message').toString()
        case PostActionType.ForwardMessageAction:
            return i18n.t('post_action_forward_message').toString()
        default:
            return ''
    }
}
