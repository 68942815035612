var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"mb-2",attrs:{"size":"small","title":_vm.actionLabel(_vm.action.action.type)}},[_c('template',{slot:"extra"},[_c('a-button',{staticClass:"mr-2",attrs:{"icon":"arrow-up","disabled":_vm.isMoveUpButtonDisabled || _vm.disabled},on:{"click":function($event){return _vm.$emit('moveUp')}}}),_c('a-button',{staticClass:"mr-2",attrs:{"icon":"arrow-down","disabled":_vm.isMoveDownButtonDisabled || _vm.disabled},on:{"click":function($event){return _vm.$emit('moveDown')}}}),_c('a-button',{attrs:{"type":"danger","disabled":_vm.disabled,"icon":"delete"},on:{"click":function($event){return _vm.$emit('remove')}}})],1),_c('time-unit-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.action,
        'prefix': 'post_action_',
        'step': _vm.StepUnit.Second,
        'key': 'delay',
        'disabled': _vm.disabled
      }
    }}}),(_vm.action.action.type === _vm.PostActionType.ForwardMessageAction)?_c('div',[_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.action.action,
          'key': 'mode',
          'prefix': 'forward_action_',
          'options': _vm.forwardMessageActionModeOptions,
          'clearable': false,
        }
      }}}),_c('channels-info-table',{attrs:{"targets":_vm.action.action.targets},on:{"update-targets":function($event){_vm.action.action.targets = $event}}})],1):_vm._e(),(_vm.action.action.type === _vm.PostActionType.NotifyUsersAction)?_c('div',[_c('users-info-table',{attrs:{"values":_vm.action.action.users},on:{"input":function($event){_vm.action.action.users = $event}}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': Object.assign({ 'model': _vm.action.action }, _vm.messageEditorArgs)
      }}})],1):_vm._e(),(_vm.action.action.type === _vm.PostActionType.NotifyUserAction)?_c('div',[_c('chips-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.action.action,
          'key': 'users',
          'prefix': 'post_',
          'disabled': _vm.disabled
        }
      }}}),_c('editor-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.action.action,
          'key': 'message',
          'disabled': _vm.disabled
        }
      }}})],1):_vm._e(),([_vm.PostActionType.SendCommentAction, _vm.PostActionType.SendReplyCommentAction, _vm.PostActionType.SendReplyMessageAction, _vm.PostActionType.EditPostAction].includes(_vm.action.action.type))?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': Object.assign({ 'model': _vm.action.action.message }, _vm.messageEditorArgs)
    }}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }