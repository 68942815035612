import {
    EditPostAction,
    ForwarMessageActionMode,
    IPostActionMessage,
    MessageVariants,
    NotifyUserAction,
    Poll,
    PostAction,
    PostActionType,
    PostMessage,
    PostReaction,
    PostType,
    Quiz,
    SendCommentActionMessageObject
} from '@/includes/types/Post.types'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component
export default class ActionsListMixin extends Vue {

    actionList(postMessage?: PostMessage | Poll | Quiz, postActions?: Array<PostAction> | Array<PostReaction>): Array<IPostActionMessage & { label: string; icon: string, disabled?:boolean }> {
        return [
            {
                label: this.$t('post_action_send_comment').toString(),
                icon: 'add_comment',
                action: {
                    type: PostActionType.SendCommentAction,
                    message: this.sendCommentActionMessageObject()
                },
            },
            ...postMessage === undefined || (postMessage && postMessage.type === PostType.Post) ? [ {
                label: this.$t('post_action_edit_post').toString(),
                icon: 'edit_note',
                action: {
                    type: PostActionType.EditPostAction,
                    message: this.purePostMessages(postMessage),
                } as EditPostAction
            } ] : [],
            {
                label: this.$t('post_action_send_reply_comment').toString(),
                icon: 'reply',
                action: {
                    type: PostActionType.SendReplyCommentAction,
                    message: this.messageObject()
                }
            },
            {
                label: this.$t('post_action_send_reply_message').toString(),
                icon: 'reply_all',
                action: {
                    type: PostActionType.SendReplyMessageAction,
                    message: this.messageObject()
                }
            },
            ...postActions && (postActions as any).find(a => a.action.type === PostActionType.NotifyUserAction) ? [ {
                label: this.$t('post_action_notify_user').toString(),
                icon: 'notifications',
                disabled: true,
                action: {
                    type: PostActionType.NotifyUserAction,
                    message: '',
                    users: []
                } as NotifyUserAction,
            } ] : [ ],
            {
                label: this.$t('post_action_new_notify_users').toString(),
                icon: 'notifications',
                action: {
                    type: PostActionType.NotifyUsersAction,
                    message: this.messageObject().variants,
                    users: []
                }
            },
            {
                label: this.$t('post_action_forward_message').toString(),
                icon: 'sync_alt',
                action: {
                    type: PostActionType.ForwardMessageAction,
                    mode: ForwarMessageActionMode.Forward,
                    targets:  [  ]
                }
            },
            {
                label: this.$t('post_action_delete_post').toString(),
                icon: 'delete_sweep',
                action: {
                    type: PostActionType.DeletePostAction,

                }
            },
            {
                label: this.$t('post_action_pin_message').toString(),
                icon: 'push_pin',
                action: {
                    type: PostActionType.PinMessageAction,
                }
            },
            {
                label: this.$t('post_action_pin_silent_message').toString(),
                icon: 'unsubscribe',
                action: {
                    type: PostActionType.PinSilentMessageAction,
                }
            },
            {
                label: this.$t('post_action_unpin_message').toString(),
                icon: 'speaker_notes_off',
                action: {
                    type: PostActionType.UnpinMessageAction,
                }
            },
            {
                label: this.$t('post_action_pin_chat_silent_message').toString(),
                icon: 'attach_email',
                action: {
                    type: PostActionType.PinChatSilentMessageAction,
                }
            },
            {
                label: this.$t('post_action_pin_chat_message').toString(),
                icon: 'chat_bubble',
                action: {
                    type: PostActionType.PinChatMessageAction,
                }
            },
            {
                label: this.$t('post_action_unpin_chat_message').toString(),
                icon: 'near_me_disabled',
                action: {
                    type: PostActionType.UnpinChatMessageAction,
                }
            }
        ]
    }

    sendCommentActionMessageObject():SendCommentActionMessageObject {
        return {
            type: PostType.Post,
            variants: [ {
                'text': '',
                'buttons': [],
                'disable_link_preview': false,
                'attachments': []
            } ]
        }
    }

    messageObject(): PostMessage {
        return {
            type: PostType.Post,
            variants: [ {
                'pin': false,
                'text': '',
                'buttons': [],
                'disable_notify': false,
                'remove_previous': false,
                'disable_link_preview': false,
                'attachments': []
            } ]
        }
    }

    purePostMessages(messages?: PostMessage): PostMessage {
            let messagesCopy = cloneDeep(messages)

            if (messagesCopy && messagesCopy.variants.length) {
                messagesCopy.variants = messagesCopy.variants.map((v: MessageVariants) => {
                    delete v.attachments
                    return v
                })

                return messagesCopy
            }

            return this.messageObject()
     }
}
