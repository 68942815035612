
























































import { IPostActionMessage, PostActionType, PostType } from '@/includes/types/Post.types'

import Vue from 'vue'
import { Prop, VModel } from 'vue-property-decorator'
import Component from 'vue-class-component'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

@Component({
  components: {
    HelpMessage,
  }
})
export default class PostActionTypePopup extends Vue {
  @VModel() isPopupActive !: boolean

  @Prop({ type: String }) postType !: PostType

  @Prop() actionList!: Array<IPostActionMessage & { label: string, icon: string, disabled?:boolean }>

  @Prop({ type:Boolean, default: false }) validateActions!:boolean

  groupActions = [ PostActionType.SendCommentAction, PostActionType.SendReplyCommentAction, PostActionType.PinChatMessageAction, PostActionType.UnpinChatMessageAction, PostActionType.PinChatSilentMessageAction ]

  isActionDisabled(actionType:PostActionType):boolean {
    if (this.validateActions && this.groupActions.includes(actionType)) {
      return !this.$store.state.hasSelectedPostTargetsLinkedChat;
    } else {
      return false
    }
  }

  handleClick(action: IPostActionMessage & { label: string, icon: string, disabled?:boolean }):void {
    if (this.validateActions && this.groupActions.includes(action.action.type)) {
      if (this.$store.state.hasSelectedPostTargetsLinkedChat) {
        this.$emit('addAction', action)
      }
    } else if (action.disabled === undefined) {
      this.$emit('addAction', action)
    }
  }
}
