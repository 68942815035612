












































































































































































































































import { PostActionType, PostCommentsStateTypes, PostType, Style } from '@/includes/types/Post.types'
import { InputSetups } from '@/mixins/input-setups'
import ActionList from './Actions/ActionList.vue'
import PostColorSelect from './PostColorSelect.vue'
import ActionsListMixin from '@/components/Post/Actions/ActionsListMixin'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { FileType } from 'piramis-base-components/src/components/File/types'
import { MessageEditorWithMediaTab } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'

import { Mixins, Prop, VModel, Watch, } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    ConfigField,
    ActionList,
    Accordion,
    PostColorSelect
  },
  data() {
    return {
      FileType,
      MessageEditorWithMediaTab,
      EditorButtonsHelpView
    }
  }
})
export default class PostStylesSetup extends Mixins<InputSetups, UseFields, ActionsListMixin>(InputSetups, UseFields, ActionsListMixin) {
  @VModel({ 'type': Object }) style !: Style

  @Prop({ required: true, type: String }) board!: string

  @Prop({ type: String, default: null }) postType!:PostType | null

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  @Prop({ type: String, default: null }) postActionType !: 'new' | 'copy' | 'edit' | 'edit-published' | 'show-suggest' | 'edit-suggest'

  file: any = null

  styleCopy: Style = {} as Style

  watermarkTypes: Array<SelectOption> = [
    { 'label': this.$t('board_style_watermark_none'), 'value': 'None' },
    { 'label': this.$t('board_style_watermark_text'), 'value': 'Text' },
    { 'label': this.$t('board_style_watermark_file'), 'value': 'File' },
  ]

  get postCommentsStateOptions(): Array<SelectOption> {
    return [
      { 'label': this.$t('post_comments_state_none'), 'value': PostCommentsStateTypes.None },
      { 'label': this.$t('post_comments_state_disabled'), 'value': PostCommentsStateTypes.Disabled },
      { 'label': this.$t('post_comments_state_linear'), 'value': PostCommentsStateTypes.Linear },
    ]
  }

  compressLevelOptions: Array<SelectOption> = [
    { 'label': this.$t('compress_level_not_compress').toString(), 'value': null },
    { 'label': '4320p', 'value': 4320 },
    { 'label': '2160p', 'value': 2160 },
    { 'label': '1440p', 'value': 1440 },
    { 'label': '1080p', 'value': 1080 },
    { 'label': '720p', 'value':  720 },
    { 'label': '576p', 'value':  576 },
    { 'label': '480p', 'value':  480 },
    { 'label': '360p', 'value':  360 },
    { 'label': '240p', 'value':  240 },
  ]

  @Watch('styleCopy')
  onStyleCopyChange(value: Style) {
    this.style = value
  }

  get filteredActions(): Array<any> {
    return this.actionList().filter(action => action.action.type !== PostActionType.EditPostAction)
  }

  onSelectChange(): void {
    if (this.styleCopy) this.styleCopy.watermark_data = null
  }

  watermarkPosition(): Array<SelectOption> {
    return [
      { 'label': this.$t('watermark_position_flip'), 'value': 'Flip' },
      { 'label': this.$t('watermark_position_center'), 'value': 'Center' },
      { 'label': this.$t('watermark_position_top_right'), 'value': 'TopRight' },
      { 'label': this.$t('watermark_position_top_left'), 'value': 'TopLeft' },
      { 'label': this.$t('watermark_position_bottom_right'), 'value': 'BottomRight' },
      { 'label': this.$t('watermark_position_bottom_left'), 'value': 'BottomLeft' },
    ]
  }

  actionsAccordionOpen = false

  created() {
    if (this.style) {
      if (this.style.data === null) {
        this.style.data = {
          color: '',
          post_comments_state: PostCommentsStateTypes.None,
          post_price: 0
        }
      }

      this.styleCopy = cloneDeep(this.style)

      if (this.style.actions && this.style.actions.length) {
        this.actionsAccordionOpen = true
      }
    }
  }
}
