import { MessageVariants, PostAction, PostActionType, PostReaction } from '@/includes/types/Post.types'

type MessageVariant = MessageVariants | Omit<MessageVariants, 'pin' | 'remove_previous' | 'disable_notify'>

const messageTextIsFilled = (message: MessageVariant) => (message.text !== '' && message.text !== '\n') || message.attachments && message.attachments.length

function checkReaction(reaction: PostReaction | PostAction):boolean {
    const { action } = reaction

    if (action.type === PostActionType.SendCommentAction
        || action.type === PostActionType.SendReplyMessageAction
        || action.type === PostActionType.EditPostAction
        || action.type === PostActionType.SendReplyCommentAction
    ) {
        if (!action.message.variants.length) {
            return false
        }

        return action.message.variants.every(messageTextIsFilled)
    }

    if (action.type === PostActionType.NotifyUsersAction) {
        return action.message.every(messageTextIsFilled) && action.users.length > 0
    }

    if (action.type === PostActionType.NotifyUserAction) {
        return action.message.trim() !== '' && action.users.length > 0
    }

    if (action.type === PostActionType.ForwardMessageAction) {
        return action.targets.length > 0
    }

    return action.type === PostActionType.DeletePostAction
        || action.type === PostActionType.PinMessageAction
        || action.type === PostActionType.PinSilentMessageAction
        || action.type === PostActionType.UnpinMessageAction
        || action.type === PostActionType.PinChatMessageAction
        || action.type === PostActionType.PinChatSilentMessageAction
        || action.type === PostActionType.UnpinChatMessageAction
}

export default function validateActions(actions:Array<PostReaction> | Array<PostAction>):boolean {
    const results = actions.map(checkReaction)

    return results.every(result => result)
}
